<template>
    <section class="mis-cursos custom-scroll overflow-auto px-4" style="height:calc(100vh - 240px);">
        <div class="row mx-0 j-center pb-4 pt-4">
            <div v-for="(carpeta,c) in carpetas" :key="c" class="col-auto my-3">
                <router-link :to="{name: 'mis-cursos.carpeta.ver', params:{id_carpeta: carpeta.id_carpeta}}">
                    <div class="border br-12 card-curso p-2">
                        <img :src="carpeta.imagen_carpeta" height="180" width="100%" class="obj-cover br-t-12" />
                        <div class="row mx-0 mt-3">
                            <div class="col-12 px-0 text-gris nombre f-600 f-18">
                                {{ carpeta.nombre_carpeta }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 a-center">
                            <div class="col px-0 br-12" style="height:12px;background-color:#e2dfdf;">
                                <div class="br-12 h-100 bg-gr-general" :style="`width: ${carpeta.porcentaje}%`" />
                            </div>
                            <div class="col-auto pr-0 f-16 text-gris">
                                {{ carpeta.cantAvance }}/{{ carpeta.cantCursos }}
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div v-if="carpetas.length <=0" class="row">
                No se han creado cursos
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    middleware:['auth'],

    data(){
        return{
            tipo_vista: 1,
            buscar: '',
            cursos: [
                {
                    nombre_carpeta: 'Cursos de recurso humano',
                    opened: false,
                    cantidad: '1/4',
                    cursos_sin_finalizar: [
                        {
                            porcentaje: 50,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            porcentaje: 80,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            porcentaje: 0,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            nuevo: true,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        }
                    ],
                    opened_inside: false,
                    cursos_finalizados: [
                        {
                            porcentaje: 50,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            porcentaje: 100,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            porcentaje: 0,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        }
                    ]
                },
                {
                    nombre_carpeta: 'Cursos de recurso humano',
                    opened: false,
                    cantidad: '1/4',
                    cursos_sin_finalizar: [
                        {
                            porcentaje: 50,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            porcentaje: 80,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            porcentaje: 0,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            nuevo: true,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        }
                    ],
                    opened_inside: false,
                    cursos_finalizados: [
                        {
                            porcentaje: 50,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            porcentaje: 100,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        },
                        {
                            porcentaje: 0,
                            nombre_curso: 'Entrevistas para nuevo personal'
                        }
                    ]
                }
            ]
        }
    },
    computed:{
        ...mapGetters({
            carpetas: 'cursos/mis_cursos/carpetas'
        })
    },
    watch: {
        '$route.params.tipo_vista': function(){
            this.tipo_vista = this.$route.params.tipo_vista
        }
    },
    mounted(){
        this.listarCarpetas() 
    },
    methods: {
        listarCarpetas(){
            this.$store.dispatch('cursos/mis_cursos/actionObtenerCarpetasUsuario')
        },
    }
}
</script>

<style lang="scss" scoped>
.card-curso{
    /* min-height:200px; */
    width: 325px;
    border: 1px solid #DFDFDF;
}
.header-drop{
    background: #F8F8F8;
    border: 1px solid #DFDFDF;
    border-radius: 12px;
}
.nombre{
    height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>
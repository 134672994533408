<template>
    <div>
        <div class="row mx-0 justify-content-end py-4">
            <div class="col-lg-4">
                <el-input v-model="buscar" class="w-100 input-radius-12" suffix-icon="icon-search" placeholder="Buscar curso..." />
            </div>
        </div>
        <div class="col-12 custom-scroll overflow-auto" style="height: calc(100vh - 330px )">
            <div v-for="(data, d) in carpetasFiltro" :key="d" class="row mx-0">
                <div class="col-12 header-drop py-2 mb-3 cr-pointer" @click="data.opened = !data.opened">
                    <div class="row mx-0 a-center title py-2 br-10">
                        <div class="col-auto text-gris f-600 f-18">
                            {{ data.name_carpeta }}
                        </div>
                        <div class="col-auto ml-auto d-middle">
                            {{ data.cant_avance }} / {{ data.cant_cursos }}
                        </div>
                        <div class="col-auto">
                            <i :class="data.opened ? 'icon-up-open' : 'icon-down-open'" class="cr-pointer text-general" />
                        </div>
                    </div>
                    <div class="col f-600 text-gris f-18 pl-2 cr-pointer" @click="$router.push({name: 'mis-cursos.ver.curso', params: { id_curso: curso.id_curso }})">
                        {{ curso.nombre_curso }}
                    </div>
                </div>
                <div v-if="data.opened" class="col-12 mt-1">
                    <div v-for="(curso, c) in data.cursos_sin_finalizar" :key="c" class="row mx-0 my-2">
                        <div class="col-2 d-middle-center px-0">
                            <p v-if="!curso.nuevo" :class="setColorText(curso.porcentaje)" class="text-center f-18">
                                {{ curso.avance }} %
                            </p>
                            <div v-else class="bg-purple text-white text-center f-15 br-5 py-1 px-3">
                                NUEVO
                            </div>
                        </div>
                        <div class="col f-600 text-gris f-18 pl-2 cr-pointer" @click="$router.push({name: 'mis-cursos.ver.curso', params: { id_curso: curso.id_curso }})">
                            {{ curso.nombre_curso }}
                        </div>
                        <div class="col-3">
                            <el-tooltip placement="bottom" content="Fecha de Visualizacion" effect="light">
                                <p class="text-gris text-center f-600">
                                    {{ formatearFecha(curso.ultima_visualizacion,'D/M/Y') }}
                                </p>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <!-- Finalizados -->
                <div v-if="data.opened" class="col-12 my-2 pr-4">
                    <p v-if="data.cursos_finalizados.length >0" class="text-right cr-pointer" @click="data.opened_inside = !data.opened_inside">
                        <span class="f-600"> Ver Finalizados </span>
                        <i :class="data.opened_inside ? 'icon-up-open' : 'icon-down-open'" class="cr-pointer text-general" />
                    </p>
                    <template v-if="data.opened_inside">
                        <div v-for="(finalizado, f) in data.cursos_finalizados" :key="f" class="row mx-0 mt-3">
                            <div class="col-2 d-middle-center">
                                <p class="text-green f-18 f-600">
                                    {{ finalizado.avance }} %
                                </p>
                            </div>
                            <div class="col f-600 text-gris f-18 pl-2 cr-pointer" @click="$router.push({name: 'mis-cursos.ver.curso', params: { id_curso: finalizado.id }})">
                                {{ finalizado.nombre_curso }}
                            </div>
                            <div class="col-3 pr-0">
                                <el-tooltip v-if="finalizado.ultima_visualizacion" placement="bottom" content="Fecha de Visualización" effect="light">
                                    <p class="text-gris text-center f-600">
                                        {{ formatearFecha(finalizado.ultima_visualizacion,'D/M/Y') }}
                                    </p>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="carpetas.length <=0" class="row mx-0 j-center my-2">
            No se han creado cursos
        </div>
    </div>
</template>
<script>
import CarpetasCursos from '~/services/cursos/cursos_lector'
export default {
    middleware:['auth'],

    data(){
        return{
            buscar: null,
            carpetas: [],
            curso: ''
        }
    },
    computed:{
        carpetasFiltro(){
            if(this.buscar){
                const filtro = this.carpetas.filter(f => {
                    const cursos = f.cursos_sin_finalizar.filter(c => {
                        return c.nombre_curso.toLowerCase().includes(this.buscar.toLowerCase())
                    })
                    const cursosFinalizados = f.cursos_finalizados.filter(c => {
                        return c.nombre_curso.toLowerCase().includes(this.buscar.toLowerCase())
                    })
                    if(cursos.length > 0 || cursosFinalizados.length >0 ){
                        return true
                    }else{
                        return false
                    }
                })
                return filtro
            }
            return this.carpetas
        }

    },
    mounted(){
        this.listarCarpetasMosaico()   
    },
    methods: {
        async listarCarpetasMosaico(){
            try {
                const {data:{data}} = await CarpetasCursos.obtenerCursosUsuarioMosaico()
                this.carpetas = data.carpetas
            } catch (e){
                this.error_catch(e)
            }
        },
        setColorText(porcentaje){
            if( porcentaje < 100 && porcentaje >= 50 ){
                return 'text-blue f-600';
            } else if( porcentaje < 50 ){
                return 'text-gris f-600'
            }
        }
    }
}
</script>
<style lang="css" scoped>
.title{
    background: #F8F8F8;
    border: 1px solid #DFDFDF;
}
</style>